<template>
  <main class="navbar-offset-tablet salon-info-background">
    <PageNavigator :page-title="$t('salon-info.breadcrumbs-list-label')" :has-back="true" :button-back-link="'/salon'">
      <template v-slot:action>
        <div class>
          <button :disabled="salonId && !stripeTosAccepted" class="btn btn-primary px-4 py-1" @click="submitForm()">
            <span class="font-weight-medium poppins-font">
              {{ $t('common.submit-btn') }}
            </span>
          </button>
        </div>
      </template>
    </PageNavigator>
    <div class="px-1-5 pt-7-5 salon-flow salon-info">
      <div class="d-flex align-items-center mb-1-5">
        <div id="salon-info-2" v-b-tooltip.bottom.html="toolTipTemplate" class="mr-1 border-0 bg-transparent">
          <font-awesome-icon
            class="fa-w-16"
            style="font-size:16px;"
            data-toggle="tooltip"
            :icon="['fas', 'info-circle']"
          ></font-awesome-icon>
        </div>
        <p class="font-weight-semibold poppins-font mb-0 text-black">
          {{ $t('salon-info.billing-text.section-title') }}
        </p>
      </div>
      <hr class="mb-1-5 mt-0" />

      <div class="mb-1-5 pb-1 text-center">
        <img :src="require('@/assets/images/profile/protected.png')" class="mb-1" height="48px" width="40px" alt="" />
        <h6 class="mb-1-5">
          {{ $t('salon-info.billing-text.title') }}
        </h6>
        <p class="mb-0 text-black">
          <span v-html="$t('salon-info.billing-text.description.start')"></span>
          <a :href="clientAppTermsUrl" target="_blank">
            {{ $t('salon-info.billing-text.description.link') }}
          </a>
          {{ $t('salon-info.billing-text.description.and') }}
          <a :href="clientAppPrivacyUrl" target="_blank">
            {{ $t('salon-info.billing-text.description.second_link') }}
          </a>
          {{ $t('salon-info.billing-text.description.end') }}
        </p>
      </div>
      <hr class="mb-1-5 mt-0" />
      <div class="text-center mb-1-5">
        <span>{{ $t('payment-info-tooltips.iban-title') }}</span>
        <br />
        <span>{{ $t('payment-info-tooltips.iban-text') }}</span>
      </div>
      <hr class="mb-1-5 mt-0" />
      <div class="d-flex flex-wrap h-100 align-content-stretch mb-1-5">
        <div class="row">
          <div class="col-6 mb-1-5 pr-lg-2-5 pr-1">
            <label for="business_type" class="poppins-font text-black mb-0-5 font-weight-medium text-small">
              {{ $t('salon-info.billing-label.business_type') }}
            </label>
            <select
              id="business-type"
              v-model="businessType"
              v-validate="'required'"
              :data-vv-as="'Business type'"
              name="business_type"
              class="form-control w-100 px-0-5 text-center"
              style="font-size:14px;"
            >
              <option v-for="(item, key) in businessTypes" :key="`business-type-${key}`" :value="item.value">
                {{ $t(item.i18KeyLabel) }}
              </option>
            </select>
          </div>

          <InvividualAccountForm v-if="businessType === 'individual'" ref="InvividualAccountForm" />
          <CompanyAccountForm v-if="businessType === 'company'" ref="CompanyAccountForm" />
        </div>

        <hr class="mb-2-5 mt-2" />
        <div class="d-flex w-100 form-group mb-0 px-0 pt-0-5 form-check justify-content-center">
          <div class="d-flex flex-column align-items-center">
            <div class="mb-1">
              <div class="checkbox" style="width: 100%; height: 24px;">
                <input v-model="stripeTosAccepted" type="checkbox" />
                <i class="input-helper"></i>
              </div>
            </div>
            <p class="text-black mb-0 poppins-font">
              {{ $t('salon-info.billing-label.terms_text') }}
              <a :href="clientAppTermsUrl" target="_blank">{{ $t('salon-info.billing-label.terms_link') }}</a>
              {{ $t('salon-info.billing-label.terms_and') }}
              <a :href="clientAppPrivacyUrl" target="_blank">{{ $t('salon-info.billing-label.terms_link_final') }}</a>
              {{ $t('salon-info.billing-label.terms_text_final') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import config from 'config'
import PageNavigator from '@/components/PageNavigator'
import InvividualAccountForm from '@/components/stripe/forms/InvividualAccount'
import CompanyAccountForm from '@/components/stripe/forms/CompanyAccount'
import Spinner from '../Spinner'
import { mapValues, keyBy } from 'lodash'
import { loadStripe } from '@stripe/stripe-js'
import { PaymentRepository } from '@/_api/payment.repository'
import { eventHub } from '@/_utils/eventhub'

export default {
  components: {
    PageNavigator,
    InvividualAccountForm,
    CompanyAccountForm,
    Spinner
  },
  data() {
    return {
      businessType: 'individual',
      businessTypes: [
        {
          i18KeyLabel: 'salon-info.billing-label.individual',
          value: 'individual'
        },
        {
          i18KeyLabel: 'salon-info.billing-label.company',
          value: 'company'
        }
      ],
      stripeTosAccepted: false,
      verificationIds: {
        ownerFront: null,
        ownerBack: null,
        additionalOwnerFront: null,
        additionalOwnerBack: null
      },
      isSalonDataSending: false
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      userRole: 'user/getRole'
    }),
    salonId() {
      return this.accountInfo.salon_id
    },
    clientAppTermsUrl() {
      return `${config.clientAppUrl}/agb`
    },
    clientAppPrivacyUrl() {
      return `${config.clientAppUrl}/privacy-policy`
    }
  },
  mounted() {
    const publicStripeKey = config.stripePK

    loadStripe(publicStripeKey)
      .then(response => (this.stripe = response))
      .catch(error => console.error(error, 'error stripe'))
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSalonDataSending) {
      this.leaveConfirmMessage()
    } else {
      next()
    }
  },
  methods: {
    ...mapActions({
      sendToModeration: 'salons/sendToModeration',
      getAccountInfo: 'user/getAccountInfo',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    addLeaveProcessEventListener() {
      window.addEventListener('beforeunload', this.leaveProcess)
    },
    removeLeaveProcessEventListener() {
      window.removeEventListener('beforeunload', this.leaveProcess)
    },
    sendToModerationRequest(payload) {
      if (this.salonId !== undefined) {
        this.isSalonDataSending = false
        this.sendToModeration(payload)
          .then(data => {
            this.setSuccess({
              type: 'success',
              messageI18Key: 'general.alerts.request_moderation_success'
            })

            this.$router.push('/salon')
          })
          .catch(error => {
            if (error.data !== null) {
              let blockNameI18Keys = []
              let blockNameI18KeysString = ''

              error.data.forEach(block => {
                blockNameI18Keys.push(this.$t(`general.moderation.${block}`))
              })

              blockNameI18KeysString = blockNameI18Keys.join(', ')

              this.setError({
                type: 'info',
                message: `${this.$t(`general.moderation.main_message`)} ${blockNameI18KeysString}`
              })
            }
          })
      }
    },
    toolTipTemplate() {
      const { title, conditions_1, conditions_2, conditions_3, conditions_4, conditions_5 } = this.$t(
        'salon-info.billing-text.tooltip'
      )
      return `<div class="documents-list">
            ${title}
            <ul style="width: 100%;">
              <li>${conditions_1}</li>
              <li>${conditions_2}</li>
              <li>${conditions_3}</li>
              <li>${conditions_4}</li>
              <li>${conditions_5}</li>
            </ul>
        </div>`
    },
    submitStripeAccount() {
      let accountData = {
        business_type: this.businessType,
        tos_shown_and_accepted: this.stripeTosAccepted
      }

      if (this.businessType === 'individual') {
        let individual = this.$refs.InvividualAccountForm.generateStripeTokenData()

        individual.individual.verification = {
          document: {
            front: this.verificationIds.ownerFront,
            back: this.verificationIds.ownerBack
          },
          additional_document: {
            front: this.verificationIds.additionalOwnerFront,
            back: this.verificationIds.additionalOwnerBack
          }
        }

        accountData = {
          ...accountData,
          ...individual
        }
      } else if (this.businessType === 'company') {
        let company = this.$refs.CompanyAccountForm.generateStripeTokenData()

        accountData = {
          ...accountData,
          ...company
        }
      }

      return this.stripe.createToken('account', accountData)
    },
    submitStripeBankAccount() {
      const stripeTokenData =
        this.businessType === 'individual'
          ? this.$refs.InvividualAccountForm.generateStripeTokenData()
          : this.$refs.CompanyAccountForm.generateStripeTokenData()

      return this.stripe.createToken('bank_account', {
        country: 'CH',
        currency: 'chf',
        account_number: stripeTokenData.account_number,
        account_holder_name: stripeTokenData.account_holder_name
      })
    },
    submitPersonAccount() {
      const stripeTokenData =
        this.businessType === 'individual'
          ? this.$refs.InvividualAccountForm.generateStripeTokenData()
          : this.$refs.CompanyAccountForm.generateStripeTokenData()

      stripeTokenData.person.verification = {
        document: {
          front: this.verificationIds.ownerFront,
          back: this.verificationIds.ownerBack
        },
        additional_document: {
          front: this.verificationIds.additionalOwnerFront,
          back: this.verificationIds.additionalOwnerBack
        }
      }
      return this.stripe.createToken('person', stripeTokenData.person)
    },
    sendAllStripeQueries() {
      return this.businessType === 'individual'
        ? Promise.all([this.submitStripeAccount(), this.submitStripeBankAccount()])
        : Promise.all([this.submitStripeAccount(), this.submitStripeBankAccount(), this.submitPersonAccount()])
    },
    submitVerificationDocuments() {
      const ref = this.businessType === 'individual' ? 'InvividualAccountForm' : 'CompanyAccountForm'

      const sideNames = Object.keys(this.$refs[ref].documentImages)

      const imagesQueries = sideNames.map(side => {
        let formData = new FormData()
        formData.append('file', this.$refs[ref].documentImages[side])
        formData.append('purpose', 'identity_document')
        return PaymentRepository.stripeFilesVerification(formData)
      })
      return Promise.all(imagesQueries)
    },
    submitForm() {
      const ref = this.businessType === 'individual' ? 'InvividualAccountForm' : 'CompanyAccountForm'

      this.$refs[ref].$validator.validateAll().then(result => {
        if (result && this.salonId) {
          let payload = {
            salon_id: this.salonId,
            country_iso: 'CH'
          }

          if (this.userRole === 'owner') {
            payload = {
              ...payload,
              ...{ owner_id: this.accountInfo.id }
            }
          }

          const isDocImageAbsent = Object.values(this.$refs[ref].documentImages).includes(null)

          if (isDocImageAbsent) {
            this.setError({
              type: 'doc_image_absent',
              statusCode: 0,
              messageI18Key: 'salon-info.alert.wrong_doc'
            })
            return
          }

          eventHub.$emit('before-request')
          this.isSalonDataSending = true
          this.addLeaveProcessEventListener()

          this.submitVerificationDocuments().then(fileIds => {
            this.verificationIds = {
              ownerFront: fileIds[0].data.id,
              ownerBack: fileIds[1].data.id,
              additionalOwnerFront: fileIds[2].data.id,
              additionalOwnerBack: fileIds[3].data.id
            }

            eventHub.$emit('before-request')

            this.sendAllStripeQueries()
              .then(responses => {
                if (responses.find(response => response.error))
                  throw new Error(responses.find(response => response.error).error.message)

                if (this.businessType === 'individual') {
                  payload.stripe_acc_tkn = responses[0].token.id
                  payload.stripe_bank_tkn = responses[1].token.id
                } else if (this.businessType === 'company') {
                  payload.stripe_acc_tkn = responses[0].token.id
                  payload.stripe_bank_tkn = responses[1].token.id
                  payload.stripe_person_tkn = responses[2].token.id
                }

                this.sendToModerationRequest(payload)
                eventHub.$emit('before-request')
              })
              .catch(error => {
                eventHub.$emit('request-error')
                this.removeLeaveProcessEventListener()
                this.isSalonDataSending = false

                this.setError({
                  type: 'create_salon',
                  statusCode: 0,
                  message: error.message
                })
              })
          })
        }
      })
    },
    leaveConfirmMessage() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h3 text-center mb-0 text-black pt-4 pb-1-5'] }, [
          this.$t('salon-info.confirm.leave_on_saved_form_title')
        ]),
        h('p', { class: ['text-center pb-0 mb-0 lh-1-5'] }, [this.$t('salon-info.confirm.leave_on_saved_form_title')])
      ])

      return this.$bvModal
        .msgBoxOk([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          okOnly: true
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    }
  }
}
</script>
