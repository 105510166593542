<template>
  <div class="col-12">
    <div class="row">
      <div class="col-md-6 pr-lg-2-5 pr-1">
        <div v-for="(field, index) in paymentFormFields" :key="`${index}-payment-field`">
          <div class="d-flex">
            <label
              v-if="field.tag === 'input'"
              :for="field.name"
              :class="{ 'pt-0-3': field.name === 'iban' }"
              class="poppins-font text-black mb-0-5 font-weight-medium text-small"
              >{{ $t(field.i18KeyLabel) }}</label
            >
          </div>
          <div v-if="field.tag === 'input'" class="input-group mb-1-5">
            <input
              :id="field.name"
              v-model="field.value"
              v-validate="field.validateRules"
              v-mask="field.name === 'iban' ? 'CH####################' : nomask"
              :name="field.name"
              :data-vv-as="$t(field.i18KeyLabel)"
              :type="field.type"
              :placeholder="$t(field.i18KeyPlaceholder)"
              masked="false"
              class="form-control w-100 px-1-5"
              autocomplete="new-password"
            />
            <span v-show="errors.has(field.name)" class="error-message mt-1 col-12 pl-0">
              {{ errors.first(field.name) }}
            </span>
          </div>

          <div v-if="field.tag === 'several-fields'" class="input-group mb-1-5">
            <div
              v-for="(childCompInput, childCompIndex) in field.children"
              :key="`${childCompInput.name}-payment-multi-field`"
              :class="{
                'col-3': (childCompIndex === 0 || childCompIndex === 2) && field.children.length > 2,
                'col-6': field.children.length < 3 || childCompIndex === 1,
                'pl-0': childCompIndex === 0,
                'pr-0': childCompIndex === field.children.length - 1
              }"
            >
              <label :for="childCompInput.name" class="poppins-font text-black mb-0-5 font-weight-medium text-small">{{
                $t(childCompInput.i18KeyLabel)
              }}</label>
              <select
                v-if="childCompInput.tag === 'select'"
                :id="childCompInput.name"
                v-model.number="childCompInput.value"
                v-validate="childCompInput.validateRules"
                :data-vv-as="$t(childCompInput.i18KeyLabel) || $t(childCompInput.i18KeyPlaceholder)"
                :name="childCompInput.name"
                :placeholder="$t(childCompInput.i18KeyPlaceholder)"
                :disabled="childCompInput.name === 'dobDay' ? dayList.length === 0 : false"
                class="form-control w-100 px-0-5 text-center"
                style="font-size:14px;"
              >
                <option style="display: none;" selected="selected" value>
                  {{ selectPlaceholder(childCompInput) }}
                </option>
                <option
                  v-for="(option, key) in childCompInput.name !== 'dobDay' ? childCompInput.data : dayList"
                  :key="`top-${key}`"
                  :value="option"
                  >{{ option }}</option
                >
              </select>
              <input
                v-if="childCompInput.tag === 'input'"
                v-model="childCompInput.value"
                v-validate="childCompInput.validateRules"
                v-mask="childCompInput.name == 'accountHolderPhone' ? '##########' : nomask"
                :name="childCompInput.name"
                :data-vv-as="$t(childCompInput.i18KeyLabel)"
                :maxlength="childCompInput.size"
                class="form-control w-100 px-1-5"
                :placeholder="$t(childCompInput.i18KeyPlaceholder)"
                :masked="false"
                :disabled="childCompInput.name === 'countryISO' ? true : false"
                autocomplete="new-password"
              />

              <span v-show="errors.has(childCompInput.name)" class="error-message mt-1 col-12 pl-0">
                {{ errors.first(childCompInput.name) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 pl-1 pl-lg-3-5 pt-0 row-t-md-n1">
        <div class="row mb-2-5">
          <div class="col-12">
            <p class="h3 text-black">
              {{ $t('salon-info.billing-label.identity_documents') }}

              <span id="documents-info-1" class="mr-1 border-0 bg-transparent align-middle cursor-pointer">
                <font-awesome-icon
                  class="fa-w-16"
                  style="font-size:16px;"
                  data-toggle="tooltip"
                  :icon="['fas', 'info-circle']"
                ></font-awesome-icon>
              </span>

              <b-tooltip
                target="documents-info-1"
                :class="'mr-1 border-0 bg-transparent'"
                triggers="hover click"
                placement="bottom"
                custom-class="text-left"
              >
                <b>{{ $t('salon-info.billing-tooltips.identification_doc.title') }}</b> <br />
                {{ $t('salon-info.billing-tooltips.identification_doc.1_point') }} <br />
                {{ $t('salon-info.billing-tooltips.identification_doc.2_point') }} <br />
                {{ $t('salon-info.billing-tooltips.identification_doc.3_point') }} <br />
                {{ $t('salon-info.billing-tooltips.identification_doc.4_point') }} <br />
              </b-tooltip>
            </p>
          </div>
          <div class="col-6">
            <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">{{
              $t('salon-info.billing-label.front_doc_photo')
            }}</label>

            <div
              style="max-width: none; height: 156px;"
              class="position-relative d-flex justify-content-center align-items-center edit-profile-img col px-1 mr-3-5 mb-2-5"
            >
              <img
                v-if="imagesToDisplay.front"
                style="width: 100%; object-fit: cover;"
                :src="imagesToDisplay.front"
                alt
                class="mw-100 mh-100 position-relative"
              />
              <img
                v-else
                style="z-index: 2;"
                src="@/assets/images/empty-doc-image.svg"
                alt
                class="mw-100 mh-100 position-relative"
              />
              <div style="z-index: 1;" class="position-absolute">
                <input
                  id="frontDocImage"
                  ref="frontDocImage"
                  type="file"
                  class="custom-file-upload"
                  @change="onUploadDocument($event, 'front')"
                />
                <label class="custom-file-label" :class="{ 'doc-image': !imagesToDisplay.front }" for="frontDocImage">
                  <span class="photocamera-icon"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">{{
              $t('salon-info.billing-label.back_doc_photo')
            }}</label>

            <div
              style="max-width: none; height: 156px;"
              class="position-relative d-flex justify-content-center align-items-center edit-profile-img col px-1 mb-2-5"
            >
              <img
                v-if="imagesToDisplay.back"
                :style="{ zIndex: imagesToDisplay.back ? 0 : 3 }"
                style="width: 100%; object-fit: cover;"
                :src="imagesToDisplay.back"
                alt
                class="mw-100 mh-100 position-relative"
              />
              <img
                v-else
                style="z-index: 2;"
                src="@/assets/images/empty-doc-image.svg"
                alt
                class="mw-100 mh-100 position-relative"
              />
              <div class="position-absolute">
                <input
                  id="backDocImage"
                  ref="backDocImage"
                  type="file"
                  class="custom-file-upload"
                  @change="onUploadDocument($event, 'back')"
                />
                <label class="custom-file-label" :class="{ 'doc-image': !imagesToDisplay.back }" for="backDocImage">
                  <span class="photocamera-icon"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p class="h3 text-black">
              {{ $t('salon-info.billing-label.address_documents') }}

              <span id="address-documents-info-1" class="mr-1 border-0 bg-transparent align-middle cursor-pointer">
                <font-awesome-icon
                  class="fa-w-16"
                  style="font-size:16px;"
                  data-toggle="tooltip"
                  :icon="['fas', 'info-circle']"
                ></font-awesome-icon>
              </span>

              <b-tooltip
                target="address-documents-info-1"
                :class="'mr-1 border-0 bg-transparent'"
                triggers="hover click"
                placement="bottom"
                custom-class="text-left"
              >
                <b>{{ $t('salon-info.billing-tooltips.identification_address_doc.title') }}</b> <br />
                {{ $t('salon-info.billing-tooltips.identification_address_doc.1_point') }} <br />
                {{ $t('salon-info.billing-tooltips.identification_address_doc.2_point') }} <br />
                {{ $t('salon-info.billing-tooltips.identification_address_doc.3_point') }} <br />
                {{ $t('salon-info.billing-tooltips.identification_address_doc.4_point') }} <br />
                {{ $t('salon-info.billing-tooltips.identification_address_doc.5_point') }}
              </b-tooltip>
            </p>
          </div>
          <div class="col-6">
            <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">{{
              $t('salon-info.billing-label.front_doc_photo')
            }}</label>

            <div
              style="max-width: none; height: 156px;"
              class="position-relative d-flex justify-content-center align-items-center edit-profile-img col px-1 mr-3-5 mb-2-5"
            >
              <img
                v-if="imagesToDisplay.additionalFront"
                :style="{ zIndex: imagesToDisplay.fadditionalFront ? 0 : 3 }"
                style="width: 100%; object-fit: cover;"
                :src="imagesToDisplay.additionalFront"
                alt
                class="mw-100 mh-100 position-relative"
              />
              <img
                v-else
                style="z-index: 2;"
                src="@/assets/images/empty-doc-image.svg"
                alt
                class="mw-100 mh-100 position-relative"
              />
              <div style="z-index: 1;" class="position-absolute">
                <input
                  id="additionalFrontDocImage"
                  ref="additionalFrontDocImage"
                  type="file"
                  class="custom-file-upload"
                  @change="onUploadDocument($event, 'additionalFront')"
                />
                <label
                  class="custom-file-label"
                  :class="{ 'doc-image': !imagesToDisplay.additionalFront }"
                  for="additionalFrontDocImage"
                >
                  <span class="photocamera-icon"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">{{
              $t('salon-info.billing-label.back_doc_photo')
            }}</label>

            <div
              style="max-width: none; height: 156px;"
              class="position-relative d-flex justify-content-center align-items-center edit-profile-img col px-1 mb-2-5"
            >
              <img
                v-if="imagesToDisplay.additionalBack"
                :style="{ zIndex: imagesToDisplay.back ? 0 : 3 }"
                style="width: 100%; object-fit: cover;"
                :src="imagesToDisplay.additionalBack"
                alt
                class="mw-100 mh-100 position-relative"
              />
              <img
                v-else
                style="z-index: 2;"
                src="@/assets/images/empty-doc-image.svg"
                alt
                class="mw-100 mh-100 position-relative"
              />
              <div class="position-absolute">
                <input
                  id="additionalBackDocImage"
                  ref="additionalBackDocImage"
                  type="file"
                  class="custom-file-upload"
                  @change="onUploadDocument($event, 'additionalBack')"
                />
                <label
                  class="custom-file-label"
                  :class="{ 'doc-image': !imagesToDisplay.additionalBack }"
                  for="additionalBackDocImage"
                >
                  <span class="photocamera-icon"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'InvividualAccountForm',
  data() {
    return {
      imagesToDisplay: {
        front: null,
        back: null,
        additionalFront: null,
        additionalBack: null
      },
      documentImages: {
        front: null,
        back: null,
        additionalFront: null,
        additionalBack: null
      },
      paymentFormFields: [
        {
          i18KeyLabel: 'salon-info.billing-label.iban',
          type: 'text',
          tag: 'input',
          name: 'iban',
          value: '',
          validateRules: 'required|min:2|max:40',
          i18KeyPlaceholder: 'salon-info.billing-label.iban_placeholder'
        },
        {
          i18KeyLabel: 'salon-info.billing-label.name',
          type: 'text',
          tag: 'input',
          name: 'accountHolderName',
          value: '',
          validateRules: 'required|min:2|max:40',
          i18KeyPlaceholder: 'salon-info.billing-label.name_placeholder'
        },
        {
          tag: 'several-fields',
          children: [
            {
              i18KeyLabel: 'salon-info.billing-label.phone',
              type: 'text',
              tag: 'input',
              name: 'accountHolderPhone',
              value: '',
              validateRules: 'required|min:5|max:13',
              i18KeyPlaceholder: 'salon-info.billing-label.phone_placeholder'
            },
            {
              i18KeyLabel: 'salon-info.billing-label.email',
              type: 'email',
              tag: 'input',
              name: 'acountHolderEmail',
              value: '',
              validateRules: 'required|email|min:2|max:40',
              i18KeyPlaceholder: 'salon-info.billing-label.email_placeholder'
            }
          ]
        },
        {
          tag: 'several-fields',
          children: [
            {
              i18KeyLabel: 'salon-info.billing-label.date_birth',
              data: Array.apply(null, Array(81)).map((it, index) => 1940 + index),
              tag: 'select',
              name: 'dobYear',
              value: '',
              validateRules: 'required|numeric|min:4|max:4',
              i18KeyPlaceholder: 'salon-info.billing-label.year_birth_placeholder'
            },
            {
              data: months,
              tag: 'select',
              name: 'dobMonth',
              value: '',
              validateRules: 'required|numeric',
              i18KeyPlaceholder: 'salon-info.billing-label.month_birth_placeholder'
            },
            {
              data: [],
              tag: 'select',
              name: 'dobDay',
              value: '',
              validateRules: 'required|numeric|min:1|max:2',
              i18KeyPlaceholder: 'salon-info.billing-label.date_birth_placeholder'
            }
          ]
        },
        {
          tag: 'several-fields',
          children: [
            {
              i18KeyLabel: 'salon-info.billing-label.country_iso',
              type: 'text',
              tag: 'input',
              name: 'countryISO',
              value: 'CH',
              size: 2,
              validateRules: 'required|max:2',
              i18KeyPlaceholder: 'salon-info.billing-label.country_iso_placeholder'
            },
            {
              i18KeyLabel: 'salon-info.billing-label.city',
              type: 'text',
              tag: 'input',
              name: 'city',
              value: '',
              validateRules: 'required|min:2|max:100',
              i18KeyPlaceholder: 'salon-info.billing-label.city_placeholder'
            },
            {
              i18KeyLabel: 'salon-info.billing-label.postal_code',
              type: 'text',
              tag: 'input',
              name: 'postalCode',
              value: '',
              size: 5,
              validateRules: 'required|max:5',
              i18KeyPlaceholder: 'salon-info.billing-label.postal_code_placeholder'
            }
          ]
        },
        {
          i18KeyLabel: 'salon-info.billing-label.address',
          type: 'text',
          tag: 'input',
          name: 'accountHolderAddressLine',
          value: '',
          validateRules: 'required|min:2|max:40',
          i18KeyPlaceholder: 'salon-info.billing-label.address_placeholder'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo'
    }),
    dayList() {
      let dayList = []
      let dateGoupFields = this.paymentFormFields[3]
      let selectedYear = dateGoupFields.children.find(field => field.name === 'dobYear')
      let selectedMonth = dateGoupFields.children.find(field => field.name === 'dobMonth')

      if (Boolean(selectedYear.value) && Boolean(selectedMonth.value)) {
        let countDay = moment(`${selectedYear.value}-${selectedMonth.value}`, 'YYYY-MM').daysInMonth()
        dayList = Array.apply(null, Array(countDay)).map((it, index) => index + 1)
      }

      return dayList
    }
  },
  methods: {
    generateStripeTokenData() {
      const [
        iban,
        accountHolderName,
        {
          children: [accountHolderPhone, accountHolderEmail]
        },
        {
          children: [dobYear, dobMonth, dobDay]
        },
        {
          children: [country, city, postalCode]
        },
        line
      ] = this.paymentFormFields
      return {
        account_number: iban.value,
        account_holder_name: accountHolderName.value,
        individual: {
          phone: `+41${accountHolderPhone.value.substring(1)}`,
          email: accountHolderEmail.value,
          first_name: this.accountInfo.first_name,
          last_name: this.accountInfo.last_name,
          dob: {
            day: dobDay.value,
            year: dobYear.value,
            month: dobMonth.value
          },
          address: {
            city: city.value,
            country: country.value,
            postal_code: postalCode.value,
            line1: line.value
          }
        }
      }
    },
    selectPlaceholder(field) {
      return field.name === 'dobDay'
        ? this.$t('salon-payment.date')
        : field.name === 'dobMonth'
        ? this.$t('salon-payment.month')
        : field.name === 'dobYear'
        ? this.$t('salon-payment.year')
        : this.$t('salon-payment.date')
    },
    onUploadDocument({ target }, fieldName) {
      if (!target.files.length) return
      const reader = new FileReader()
      reader.onload = e => {
        this.imagesToDisplay[fieldName] = e.target.result
      }
      this.documentImages[fieldName] = this.$refs[`${fieldName}DocImage`].files[0]
      reader.readAsDataURL(target.files[0])
    }
  }
}
</script>

<style lang="scss" scoped></style>
